import { React, useState } from "react";

function QA(props) {
    const [open, setOpen] = useState(false);

    return (
        <div className={" p-4 -mx-4 lg:-mx-6 lg:p-6 rounded-xl " + (open ? " bg-gray-50" : "") }>
        <button
            type="button"
            onClick={() => setOpen(!open)}
            className="flex space-x-2 text-gray-700 transition-colors duration-200 transform focus:outline-none hover:text-gray-500"
        >
            <svg className={"flex-shrink-0" + (open ? " transition-all duration-300 transform rotate-90" : " transition-all duration-300 transform")} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 18L15 12L9 6" stroke="#05203D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <h3 className="text-lg text-left">
            {props.title}
            </h3>
        </button>

        { open && 
            <p className="pl-8 mt-3 text-gray-600">
                {props.body}
            </p>
        }
        </div>
    );
}

export default QA;
