import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import OpenRoadmap from "./views/OpenRoadmap";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<OpenRoadmap />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
